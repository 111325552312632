// import React from "react";
import { ImgComparisonSlider } from "@img-comparison-slider/react";
import limage from "../../../common/assets/image/interior/left.png";
import SectionWrapper, { Container } from "./beforeAfter.style";
import { Link } from 'gatsby';
import Fade from 'react-reveal/Fade';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';



const Tooltip = ({ text, children }) => {
  const [visible, setVisible] = useState(false);

  const showTooltip = () => setVisible(true);
  const hideTooltip = () => setVisible(false);

  return (
    <div className="tooltip-container"
         onMouseEnter={showTooltip}
         onMouseLeave={hideTooltip}>
      {children}
      {visible && (
        <div className="tooltip-text">
          {text}
          <div className="tooltip-arrow"></div>
        </div>
      )}
    </div>
  );
};

const BeforeAfter = () => {
  const before =
    "https://lapiel.in/wp-content/uploads/2023/03/LaPiel_Hair_Transplantation_Before.png";
  const after =
    "https://lapiel.in/wp-content/uploads/2023/03/LaPiel_Hair_Transplantation_After.png";

  return (
    <SectionWrapper id="beforeAfter">
      <Container>
      <div className='action__btns'>
                <div className="linkbtn">
                  <Link to="/take-the-hair-test.php" className="btn btn-default">Take The Hair Test</Link>
                </div>
                <div className="linkbtn">
                  <Link to="/appointment.php" className="btn btn-default black">Talk to an Expert</Link> 
                </div>
              </div>
        
        <div className="container" style={{ textAlign: "left" }}>
          <Fade left><h3 className="title results_title">
            Why Hair <span className="btn_heading">Replacement?</span>
          </h3></Fade>
          
          <div className="row pt-5" style={{ alignItems: "end" }}>
            <div className="col-md-4">
              <div className="btnWrap">
              <Tooltip text="Our hair wig collection is designed to improve your appearance and confidence. Crafted with the finest materials and attention to detail, our wigs offer a seamless look that will make you feel like the best version of yourself. ">
                <Fade bottom>
                <a href="services.php" className="btn btn-default btn-lg ba-btn">
                  Enhanced Appearance
                </a></Fade>
                </Tooltip>
                <div className="emptySpace2"></div>
                <Tooltip text="Our hair wigs are crafted for a natural appearance. Each wig is expertly designed to mimic the texture, color, and movement of real hair, ensuring that they blend seamlessly with your natural hair. The result is a natural-looking wig that can be styled and customized to suit your preferences and needs. ">

                <Fade bottom>
                <a href="services.php" className="btn btn-default btn-lg black ba-btn" style={{ marginLeft: 'auto' }}>
                  Natural Looking
                </a>
                </Fade>
                 </Tooltip>
              </div>
              <div className="emptySpace"></div>
            </div>
            <div className="col-md-4">
              
            <Fade top>
              
              <p className="bef-aft-text" style={{textAlign: 'center'}}><span className="lef">Before</span> <span className="righ">After</span></p>
              <img src="/home/BaforeAfter7-1.png" alt="radiance before after hair wigs" style={{width: '85%', display: 'none'}} />
              <ImgComparisonSlider hover="hover" style={{display: 'block'}}>
                <img slot="first" src="/home/beforeWig.webp" alt="before" />
                <img slot="second" src="/home/afterWig.webp" alt="after" />
              </ImgComparisonSlider>
              <Tooltip text="Our hair wigs provide a convenient and low-maintenance solution for those seeking to enhance their natural hair or change their look. Made with high-quality materials and expert craftsmanship, our wigs are durable and long-lasting, requiring minimal upkeep to maintain their shape and appearance. ">

              <div className="btnWrap changc">
                <a href="services.php" className="btn btn-default btn-lg ba-btn">
                  Low Maintenance
                </a>
              </div>
              </Tooltip>
              </Fade>
            </div>
            <div className="col-md-4">
              <div className="btnWrap">
              <Tooltip text="Our hair wigs are not only stylish and versatile, but they are also incredibly cost-effective. Investing in a high-quality wig can save you money in the long run, as they require minimal maintenance and upkeep compared to natural hair or other hair extensions.">

              <Fade bottom>
                <a href="services.php" className="btn btn-default btn-lg ba-btn">
                  Cost effective
                </a>
                </Fade>
                </Tooltip>
                <div className="emptySpace2"></div>
                <Tooltip text="One of the key features of our wigs is their easy removal process, which allows for effortless styling and maintenance. With a secure yet gentle attachment system, our wigs can be easily taken off without causing any damage to the natural hair or scalp.">

                <Fade bottom>
                <a href="services.php" className="btn btn-default btn-lg black ba-btn" style={{ marginRight: 'auto' }}>
                  Easy to Remove
                </a>
                </Fade>
                </Tooltip>
              </div>
              <div className="emptySpace"></div>
            </div>
          </div>
        </div>

        
      </Container>
    </SectionWrapper>
  );


};




export default BeforeAfter;
