import React from "react";
import SectionWrapper, { Container } from "./aboutGrid.style";

import Fade from "react-reveal/Fade";
import { Link } from "gatsby";

const AboutGrid = () => {
  return (
    <SectionWrapper id="whoWeAre">
      <Container className="myContainerLarge">
        <div class="parentGrid">
          <div class="divGrid1">
            <Fade left>
              <p style={{ color: "#A8600E", marginBottom: "0" }}>About Us</p>
              <h2 style={{ paddingBottom: "35px" }}>
                The Best Choice For
                <br />
                Hair Transformation.
              </h2>
            </Fade>
          </div>

          <div class="divGrid2">
            <Fade right>
              <img src="/home/human-hair-wigs.jpg" alt="human hair wigs" />
            </Fade>
          </div>

          <div class="divGrid3">
            <Fade bottom>
              <p style={{ marginTop: "-45px" }}>
                At Radiance Hair Studio, our goal is to make every client look
                at their very best. Our clients receive high-quality, one-on-one
                attention. Each client receives a complete <a href="/contact.php" style={{ color: "#373737"}}>consultation</a> before
                any procedure so he or she can decide the best personal course
                of action. There are no extra charges if additional treatments
                are needed.
              </p>
              <p className="spaceddText">
                Our motive is to free you from the stress of dealing with hair
                loss and a dedication to giving you back your confidence.
              </p>
              <div className="flex">
                <Link to="/about.php" className="btn btn-default">
                  More About Us
                </Link>
                <Link
                  to="/"
                  className="playVideo"
                  style={{ marginLeft: "20px" }}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="39"
                    height="39"
                    viewBox="0 0 39 39"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_25_264)">
                      <path
                        d="M16.2865 27.1306L27.2507 20.5867C27.6476 20.3525 27.866 19.9322 27.866 19.5049C27.866 19.0864 27.653 18.6591 27.2478 18.4226L16.2849 11.8782L16.2809 11.8758C15.9015 11.6517 15.4173 11.6365 15.0154 11.867L15.013 11.8684C14.6242 12.0927 14.3844 12.5091 14.3844 12.9583V19.5049V26.0516C14.3844 26.5032 14.6249 26.9159 15.0097 27.1395C15.4142 27.3756 15.9041 27.3605 16.2865 27.1306ZM1 19.5056C1 9.2891 9.28349 1.00562 19.5 1.00562C29.7164 1.00562 38 9.2891 38 19.5056C38 29.7221 29.7164 38.0056 19.5 38.0056C9.28349 38.0056 1 29.7221 1 19.5056Z"
                        stroke="#A8600E"
                        stroke-width="2"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_25_264">
                        <rect
                          width="39"
                          height="39"
                          fill="white"
                          transform="translate(0 0.00561523)"
                        />
                      </clipPath>
                    </defs>
                  </svg>{" "}
                  Play Video
                </Link>
              </div>
            </Fade>
          </div>

          <div class="divGrid4">
            <Fade left>
              <img src="/home/men-face1.jpg" alt="women hair wigs" />
            </Fade>
          </div>

          <div class="divGrid5">
            <Fade bottom>
              <img src="/home/men-face.jpg" alt="men hair wigs" />
            </Fade>
          </div>

          <div class="divGrid6">
            <Fade bottom>
              <img src="/home/women-face.png" alt="women face" />
            </Fade>
          </div>

          <div class="divGrid7">
            <Fade right>
              <img src="/home/women-face1.jpg" alt="women hair wigs" />
            </Fade>
          </div>
        </div>
        <div
          className="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <span className="btn-close2" data-bs-dismiss="modal">
                  X
                </span>
              </div>
              {/* <div className="modal-body">
                <iframe
                  width="100%"
                  height="450"
                  src="https://www.youtube.com/embed/UFLhkbkiCfw"
                  title="Hair Replacement process | Hair Wig | Before After @ Radiance 9958585649"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></iframe>
              </div> */}
            </div>
          </div>
        </div>
      </Container>
    </SectionWrapper>
  );
};

export default AboutGrid;
