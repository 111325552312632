import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import s7 from '../../../common/assets/image/interior/icons/s7.png';
import s1 from '../../../common/assets/image/interior/icons/s1.png';

import s12 from '../../../common/assets/image/interior/icons/s12.png';
import s2 from '../../../common/assets/image/interior/icons/s2.png';

import s9 from '../../../common/assets/image/interior/icons/s9.png';
import s3 from '../../../common/assets/image/interior/icons/s3.png';

import s10 from '../../../common/assets/image/interior/icons/s10.png';
import s4 from '../../../common/assets/image/interior/icons/s4.png';

import s11 from '../../../common/assets/image/interior/icons/s11.png';
import s5 from '../../../common/assets/image/interior/icons/s5.png';

import s8 from '../../../common/assets/image/interior/icons/s8.png';
import s6 from '../../../common/assets/image/interior/icons/s6.png';

import Fade from 'react-reveal/Fade';


// import mensHairReplace from '../../../common/assets/image/interior/services/mens-hair-replacement.jpg';
// import customizedWigs from '../../../common/assets/image/interior/services/customized-wigs.jpg';
// import hairBonding from '../../../common/assets/image/interior/services/hair-bonding.jpg';
// import hairWeaving from '../../../common/assets/image/interior/services/hair-weaving.jpg';
// import hairClipping from '../../../common/assets/image/interior/services/hair-clipping.jpg';
// import womenVolumizer from '../../../common/assets/image/interior/services/women-volumizer.jpg';
import SectionWrapper, {
  Container,
} from './whyRestoration.style';
import { SectionHeader } from '../global.style';
import Heading from 'common/components/Heading';

const WhyRestoration = () => {

	useEffect(() => {
		import("bootstrap/dist/js/bootstrap.bundle.min.js").then(() => {
		  // Bootstrap JavaScript is loaded and can be used here
		  // Initialize Bootstrap components or perform other necessary operations
		});
	  }, []);
  
  return (
    <SectionWrapper id="whyRestoration">
		<Fade bottom>
		<Container class="myContainer">
		<Fade left>
        <SectionHeader className='' style={{textAlign: 'left', paddingBottom: '25px'}}>
          <Heading as="h5" content="Our Services" />
          <Heading content="Hair Wig For Men And Women in Delhi" />
        </SectionHeader>
		</Fade>
		</Container>
      </Fade>
	  <Fade bottom>
      <Container class="myContainer">
        <div className='row'>
      		<div className="col-md-3 mb-35">
					<div className="services__area-button">
      					<ul className="nav nav-pills mypx-3">
							<li className="nav-item">
								<button className="nav-item-button active" data-bs-toggle="pill" data-bs-target="#haircut">
									<img className="nav-item-button-icon" src={s10} alt="" />
									<img className="nav-item-button-icon2 white" src={s4} alt="" />
                    				Men’s Hair
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button data-bs-toggle="pill" data-bs-target="#washing" className="nav-item-button">
									<img className="nav-item-button-icon" src={s12} alt="" />
									<img className="nav-item-button-icon2 white" src={s2} alt="" />
									Women Hair
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button data-bs-toggle="pill" data-bs-target="#color" className="nav-item-button">
									<img className="nav-item-button-icon" src={s7} alt="" />
									<img className="nav-item-button-icon2 white" src={s1} alt="" />
									Customized Wigs
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button className="nav-item-button" data-bs-toggle="pill" data-bs-target="#facial">
									<img className="nav-item-button-icon" src={s9} alt="" />
									<img className="nav-item-button-icon2 white" src={s3} alt="" />
									Hair Bonding
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button data-bs-toggle="pill" data-bs-target="#shave" className="nav-item-button">
									<img className="nav-item-button-icon" src={s8} alt="" />
									<img className="nav-item-button-icon2 white" src={s6} alt="" />
									Hair Weaving
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button data-bs-toggle="pill" data-bs-target="#massage" className="nav-item-button">
									<img className="nav-item-button-icon" src={s11} alt="" />
									<img className="nav-item-button-icon2 white" src={s5} alt="" />
									Hair Clipping
								</button>
							</li>
						</ul>
            </div>
				</div>

            <div className="col-md-9">					
					<div className="tab-content">
						<div className="tab-pane fade active show" id="haircut">
							<div className="row align-items-center">
								<div className="col-md-12 px-4">
									<div className="services__area-image mb-4">
										<img className="img__full" src="/home/image 2.png" alt="" />
									</div>									
								</div>
								<div className="col-md-12 px-4">
									<div className="services__area-right ml-40 lg-ml-0">
										<div className="services__area-right-title">
											<h3>Men’s Hair Replacement System </h3>
											<p> 100% Natural human hair with a 0.3 mm base gives you a head turner look. Use any hair color, gel them up, go for a swim will full confidence as the hair bonding process makes sure the hair system stays on at all times. <br /><br />Like your own hair, shampoo them with a good conditioner of your choice and give them a blow dry them, you got the look you always dreamed of and that too unconditional. </p>
											<Link to="/hair-replacement-for-men.php" className="btn btn-default black" style={{ marginLeft: 'auto' }}>
                  Know More
                </Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="tab-pane fade" id="washing">
							<div className="row align-items-center">
								<div className="col-md-12 px-4">
									<div className="services__area-image mb-4">
										<img className="img__full" src="/home/women-hair.jpg" alt="Volumizer Hair Additions for Women" />
									</div>									
								</div>
								<div className="col-md-12 px-4">
									<div className="services__area-right ml-40 lg-ml-0">
										<div className="services__area-right-title">
											<h3>Volumizer Hair Additions for Women</h3>
											<p> Yes, you are guessing it right, not only men but women can also get their favorite hairstyles they desire. It makes a woman look beautiful with thick and long hair with the volume they always desired.<br /><br />For women, we have a long range of products and if you are in stress or worried about the same, lost all hopes of getting the natural growth back. The New You is just an appointment away. </p>
											<Link to="/volumizer-hair-additions-for-women.php" className="btn btn-default black" style={{ marginLeft: 'auto' }}>
                  Know More
                </Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="tab-pane fade" id="color">
							<div className="row align-items-center">
								<div className="col-md-12 px-4">
									<div className="services__area-image mb-4">
										<img className="img__full" src="/home/customized-wigs.jpg" alt="Customized Wigs" />
									</div>									
								</div>
								<div className="col-md-12 px-4">
									<div className="services__area-right ml-40 lg-ml-0">
										<div className="services__area-right-title">
											<h3>Customized Wigs</h3>
											<p> You might think, what there to customize in a wig. We aren't talking about the wigs which came into existence in the ’90s. Our products are customized according to your scalp measurement done by the hair experts and a sample of your hair is taken to match the texture. <br /><br />Rest is the product of your choice and our craftsmen here at RADIANCE will give your desired look with a dose of full confidence 100% Guaranteed. </p>
											<Link to="/customized-wigs-for-women.php" className="btn btn-default black" style={{ marginLeft: 'auto' }}>
                  Know More
                </Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="tab-pane fade" id="facial">
							<div className="row align-items-center">
								<div className="col-md-12 px-4">
									<div className="services__area-image mb-4">
										<img className="img__full" src="/home/Hair-Bonding.jpg" alt="Hair Bonding" />
									</div>									
								</div>
								<div className="col-md-12 px-4">
									<div className="services__area-right ml-40 lg-ml-0">
										<div className="services__area-right-title">
											<h3>Hair Bonding</h3>
											<p> When we use the term hair bonding, it means no surgery, no pain, no tools of any kind. It's just your selected product with an adhesive made for the very same reason. <br /><br />At Radiance, we give you all the latest options available in this industry and that too with a guaranteed discount on the original price. </p>
											<Link to="/hair-bonding-for-women.php" className="btn btn-default black" style={{ marginLeft: 'auto' }}>
                  Know More
                </Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="tab-pane fade" id="shave">
							<div className="row align-items-center">
								<div className="col-md-12 px-4">
									<div className="services__area-image mb-4" >
										<img className="img__full" src="/home/hairweaving1.png" alt="Hair Weaving" />
									</div>									
								</div>
								<div className="col-md-12 px-4">
									<div className="services__area-right ml-40 lg-ml-0">
										<div className="services__area-right-title">
											<h3>Hair Weaving</h3>
											<p> The term hair weaving has a different definition altogether. This is a non-surgical procedure, which is very similar to the bonding process. It is the addition of human hair extensions to make you look gorgeous. <br /><br />The only difference here is of adding the extra hair to your natural hair starting from your scalp. </p>
											<Link to="/hair-weaving-for-women.php" className="btn btn-default black" style={{ marginLeft: 'auto' }}>
                  Know More
                </Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="tab-pane fade" id="massage">
							<div className="row align-items-center">
								<div className="col-md-12 px-4">
									<div className="services__area-image mb-4">
										<img className="img__full" src="/home/hair-clipping.jpg" alt="Hair Clipping" />
									</div>									
								</div>
								<div className="col-md-12 px-4">
									<div className="services__area-right ml-40 lg-ml-0">
										<div className="services__area-right-title">
											<h3>Hair Clipping</h3>
											<p> This one option is getting a raise in its popularity spectrum. The logic here is to add or remove the desired hair extensions accordingly. With clips at one end, you can simply make your hair thick, make them longer as per your desire. <br /><br />Very easy to attach, very convenient to remove while going to bed. </p>
											<Link to="/tape-in-and-clip-in-hair-extension.php" className="btn btn-default black" style={{ marginLeft: 'auto' }}>
                  Know More
                </Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
        </div>
      </Container>
	  </Fade>
    </SectionWrapper>
  );
};

export default WhyRestoration;
