import React from 'react';
import bg2 from '../../../common/assets/image/interior/bg/bg-2.jpg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import SectionWrapper, {
  Container
} from './counters.style';

import Fade from 'react-reveal/Fade';

const Counters = () => {
  
  return (
    <SectionWrapper id="counters">
      <Container className='myContainer flexDirecColumn px-3'>
        <Fade top><h3 style={{    color: '#ffffff', marginBottom: '30px', textAlign: 'center'}}>What makes us the best <br />in the industry</h3></Fade>
        <Fade bottom>
      <div className="achivment"> 
      <div> 
            <span className="fw7">10 Year</span> 
            <span className="d-block">In Hair Solution</span> 
        </div>
        <div> 
            <span className="fw7">50+</span> 
            <span className="d-block">Different Hair Solution</span> 
        </div>
        <div> 
            <span className="fw7">50,000+</span> 
            <span className="d-block">Happy Customers</span> 
        </div>
        <div> 
            <span className="fw7 subheadstar">5.0  <FontAwesomeIcon icon={faStar} /></span> 
            <span className="d-block">Google Star Rating</span> 
        </div>
        {/* <div> 
            <span className="fw7">2</span> 
            <span className="d-block">Research and Development Department</span> 
        </div>
        <div> 
            <span className="fw7">Endorsed By</span> 
            <span className="d-block">Harvard & Monash Univercities</span> 
        </div> */}
       </div>
       </Fade>
      </Container>
    </SectionWrapper>
  );
};

export default Counters;
