import React from "react";
import SectionWrapper from "./watchVideos.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import LazyLoad from "react-lazyload";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const WatchVideos = () => {
  return (
    <SectionWrapper id="watchVideos">
      <div
        className="flex"
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <h3 className="title results_title">Watch our videos</h3>
        <div className="flex">
          <div className="swiper-button-prev7">
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
          <div className="swiper-button-next7">
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
        </div>
      </div>

      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        navigation={{
          prevEl: ".swiper-button-prev7",
          nextEl: ".swiper-button-next7",
        }}
        style={{ marginTop: "15px" }}
      >
        <SwiperSlide>
          <div className="ifrmecard">
            <LazyLoad height={227} offset={100} once>
              <iframe
                width="100%"
                height="227"
                src="https://www.youtube.com/embed/UFLhkbkiCfw"
                title="Hair Replacement process | Hair Wig | Before After @ Radiance 9958585649"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </LazyLoad>
            <div className="video-details">
              <h3>Hair Replacement process</h3>
              <p>@Radiance</p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="ifrmecard">
            <LazyLoad height={227} offset={100} once>
              <iframe
                width="100%"
                height="227"
                src="https://www.youtube.com/embed/UFLhkbkiCfw"
                title="Hair Replacement process | Hair Wig | Before After @ Radiance 9958585649"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </LazyLoad>
            <div className="video-details">
              <h3>Hair Replacement process</h3>
              <p>@Radiance</p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </SectionWrapper>
  );
};

export default WatchVideos;
