import styled from "styled-components";

const SectionWrapper = styled.section`
  padding: 81px 0;
  overflow: hidden;
  background: linear-gradient(270deg, #EAEAEA 0%, rgba(234, 234, 234, 0.00) 100%);
  
  h2 {
    font-size: 30px;
    font-weight: 700 !important;
    line-height: 1.4;
  }

  .blog_post {
    background: #ffffff;
    padding: 20px;
    border-radius: 20px;
    text-align: center;
    padding-bottom: 0;

    h3.title {
      font-size: 20px;
    }

    .thumbnail {
      margin-bottom: 0px;
    }
  }

  @media only screen and (min-width: 767px) {
    .parent {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(9, 1fr);
      grid-column-gap: 25px;
      grid-row-gap: 25px;
    }
      
      .div1 { grid-area: 1 / 1 / 5 / 4; }
      .div2 { grid-area: 2 / 4 / 6 / 7; }
      .div3 { grid-area: 5 / 1 / 9 / 4; }
      .div4 { grid-area: 6 / 4 / 10 / 7; }
  }
  
}

  @media only screen and (max-width: 1440px) {
    padding: 60px 0;
  }
  @media only screen and (max-width: 767px) {
    padding: 42px 0 12px;
    background: #ebebeb;
    .clasno {
      padding-left: 10px;
      padding-right: 10px;
  }

    .blog_post {
      padding-bottom: 20px;
      margin-bottom: 15px;
  }
  }
  @media only screen and (max-width: 480px) {
    padding-bottom: 30px;
  }
`;

export const FeatureWrapper = styled.div`
  display: flex;
  margin: 0;
  @media only screen and (max-width: 1360px) {
    margin: 0 -25px;
  }
  @media only screen and (max-width: 767px) {
    flex-wrap: wrap;
  }

  .blog_post {
    padding: 0 71.5px;
    text-align: center;
    @media only screen and (max-width: 1360px) {
      padding: 0 25px;
    }
    @media only screen and (max-width: 767px) {
      width: 50%;
      margin-bottom: 30px;
    }
    @media only screen and (max-width: 480px) {
      text-align: left;
      display: flex;
      width: 100%;
    }

    .thumbnail {
      margin: 30px 0 40px;
      @media only screen and (max-width: 1360px) {
        margin: 0 0 27px;
        img {
          width: 90px;
          height: auto;
        }
      }
      @media only screen and (max-width: 480px) {
        margin: 0;
        width: 60px;
        flex-shrink: 0;
      }
    }

    .content {
      @media only screen and (max-width: 480px) {
        width: calc(100% - 60px);
        padding-left: 20px;
      }
      h3 {
        margin: 0 0 16px;
        @media only screen and (max-width: 1360px) {
          font-size: 20px;
          margin: 0 0 12px;
        }
        @media only screen and (max-width: 480px) {
          line-height: 34px;
          margin-bottom: 7px;
        }
      }
      p {
        margin: 0;
      }
    }
  }
`;

export default SectionWrapper;
