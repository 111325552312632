import styled from "styled-components";

const SectionWrapper = styled.div`
  padding: 81px 0;

  .gReviewsIcos .swiper-button-prevW {
    position: absolute;
    bottom: 50%;
    z-index: 9;
    left: -20px;
  }
  .gReviewsIcos .swiper-button-nextW {
    position: absolute;
    bottom: 50%;
    z-index: 9;
    right: -20px;
  }

  .swiper-button-nextW.swiper-button-disabled,
  .swiper-button-prevW.swiper-button-disabled {
    opacity: 0.5;
  }
  .swiper-button-nextW,
  .swiper-button-prevW {
    color: #a8600e;
    // border: 1px solid currentColor;
    padding: 5px 10px;
    border-radius: 100%;
    margin: 5px;
  }

  h3.title {
    font-weight: 700 !important;
  }

  .whyCard {
    border-radius: 10px;
    border: 1px solid #d1d1d1;
    background: #fff;
    padding: 20px;
    min-height: 327px;
    h4 {
      font-size: 20px;
    }

    p.numberIcon {
      border: 1px solid #dedede;
      display: inline-block;
      height: 40px;
      width: 40px;
      border-radius: 100%;
      text-align: center;
      margin-bottom: 15px;
      color: #a8600e;
      background: #fff;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 6px;
      }
    }
  }

  .firstpara {
    display: flex;

    .headB {
      flex: 2;

      p {
        margin-bottom: 0;
        color: #a8600e;
      }
      h3.title {
        font-size: 28px !important;
      }
    }
    p.who-we-are-sec.pt-20 {
      flex: 3;
    }
  }

  .swiper {
    padding-bottom: 40px;

    .swiper-pagination-bullet-active {
      width: 19px;
      border-radius: 5px;
      background: #c98202 !important;
    }

    .swiper-slide.swiper-slide-next .whyCard {
      background: linear-gradient(180deg,#ffa238 0%,#a8600e 100%);
      color: #ffffff;

      p.desc {
        color: #ffffff;
      }
    }
  }

  @media only screen and (max-width: 1440px) {
    padding: 60px 0;
  }
  @media only screen and (max-width: 767px) {
    padding: 42px 0;

    .swiper-button-nextW, .iOtrhp .swiper-button-prevW {

      display: none !important;
    }

    .gReviewsIcos .swiper-button-prevW {
      left: 0px !important;
    }
    .gReviewsIcos .swiper-button-nextW {
      right: 0px !important;
    }

    .swiper-slide.swiper-slide-active .whyCard {
      background: linear-gradient(180deg,#ffa238 0%,#a8600e 100%);
      color: #ffffff;

      p.desc {
        color: #ffffff;
      }
    }

    .firstpara {
      flex-direction: column;
    }

    .whyCard{
      min-height: 0px !important;
    }

  }
`;

export const Container = styled.div`
  max-width: 1627px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1700px) {
    padding: 0 70px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 0;
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const ImageWrapper = styled.div`
  width: calc(100% - 507px);
  padding-right: 75px;
  position: relative;
  @media only screen and (max-width: 1200px) {
    width: 60%;
    padding-right: 50px;
  }
  @media only screen and (max-width: 991px) {
    padding-right: 0;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 30px;
    transform: rotateY(-180deg);
  }
`;

export const TextWrapper = styled.div`
  width: 507px;
  @media only screen and (max-width: 991px) {
    padding-left: 50px;
  }
  @media only screen and (max-width: 767px) {
    padding-left: 0;
    width: 100%;
  }

  h2 {
    @media only screen and (min-width: 1441px) {
      margin-bottom: 60px;
    }
    @media only screen and (min-width: 1440px) {
      margin-bottom: 40px;
    }
    @media only screen and (min-width: 767px) {
      margin-bottom: 30px;
    }
  }

  p {
    margin-bottom: 30px;
  }

  .learn__more-btn {
    margin-top: 20px;
  }
`;

export default SectionWrapper;
