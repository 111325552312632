import React from 'react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'gatsby';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import Container from 'common/components/UI/Container';
import SectionWrapper from './heroarea.style';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

function Heroarea() {

  return (
    <SectionWrapper id="hero__Area">
      <Container className='fullWidthContainer heroRowMain2'>
        <div className='fixedText'>
          <Fade>
            <Flip top>
              <h1>Hair Patch & Wig Solution's Start Only@ 6999 <br />
              {/* <span className='light'>Restore Your Hair. Restore Your Life</span> */}
              </h1>
            </Flip>
            <div className='action__btns'>
              <div className="linkbtn">
                <Link to="/take-the-hair-test.php" className="btn btn-default">Take The Hair Test</Link>
              </div>
              <div className="linkbtn">
                <Link to="/appointment.php" className="btn btn-default black">Talk to an Expert</Link>
              </div>
            </div>
          </Fade>
        </div>
        <Swiper
             spaceBetween={0}
             slidesPerView={1}
             autoplay={{ delay: 3000 }}
             loop={true}
             navigation={true}
        >
          <SwiperSlide>
            <div className='hero_slider' style={{ background: `url(/home/slider1.webp)` }}></div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='hero_slider' style={{ background: `url(/home/slider3.webp)` }}></div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='hero_slider' style={{ background: `url(/home/slider-4.webp)` }}></div>
          </SwiperSlide>
        </Swiper>
      </Container>
    </SectionWrapper>
  );
}

export default Heroarea;
