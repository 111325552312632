import styled from "styled-components";

const SectionWrapper = styled.div`
  padding: 81px 0;
  background: #ffffff !important;

  h2 {
    font-size: 30px;
    font-weight: 700 !important;
    line-height: 1.4;
  }

  div#whyRestoration {
    background-color: #ffffff;
  }

  .tab-pane {
    padding-top: 0;
    padding-bottom: 0;
  }

  .services__area-button ul {
    flex-direction: column;

    button.nav-item-button.active::after {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      background: #efaa4c;
      right: -4px;
      rotate: 45deg;
    }

    .nav-item button {
      display: flex;
      padding: 10px;
      align-items: center;
      width: 100%;
      gap: 15px;

      svg {
        display: none;
      }

      img {
        width: 55px;
        padding: 0 !important;
        margin: 0 !important;
      }
    }


  }

  @media only screen and (max-width: 1440px) {
    padding: 60px 0;
  }
  @media only screen and (max-width: 767px) {
    padding: 42px 0;

    header {
      padding-left: 10px;
      padding-right: 10px;
    }
    .tab-content {
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 20px;
      margin-bottom: 40px;
    }
    
  }
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1700px) {
    padding: 0 0px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 0;
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const ImageWrapper = styled.div`
  width: calc(100% - 507px);
  padding-right: 75px;
  position: relative;
  @media only screen and (max-width: 1200px) {
    width: 60%;
    padding-right: 50px;
  }
  @media only screen and (max-width: 991px) {
    padding-right: 0;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 30px;
    transform: rotateY(-180deg);
  }
`;

export const TextWrapper = styled.div`
  width: 507px;
  @media only screen and (max-width: 991px) {
    padding-left: 50px;
  }
  @media only screen and (max-width: 767px) {
    padding-left: 0;
    width: 100%;
  }

  h2 {
    @media only screen and (min-width: 1441px) {
      margin-bottom: 60px;
    }
    @media only screen and (min-width: 1440px) {
      margin-bottom: 40px;
    }
    @media only screen and (min-width: 767px) {
      margin-bottom: 30px;
    }
  }

  p {
    margin-bottom: 30px;
  }

  .learn__more-btn {
    margin-top: 20px;
  }
`;

export default SectionWrapper;
