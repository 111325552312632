import React, { Fragment } from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { interiorTheme } from "common/theme/interior";
import { DrawerProvider } from "common/contexts/DrawerContext";
import Navbar from "containers/Home/Navbar";
import Fade from "react-reveal/Fade";

import Heroarea from "containers/Home/Heroarea";
import BeforeAfter from "containers/Home/BeforeAfter";
import GoogleReviews from "containers/Home/GoogleReviews";
import AboutGrid from "containers/Home/AboutGrid";
import WhyRestoration from "containers/Home/WhyRestoration";
import Features from "containers/Home/Feature";
import CallToAction from "containers/Home/CallToAction";
import WhyChoose from "containers/Home/WhyChoose";
import Counters from "containers/Home/Counters";
import SuccessStories from "containers/Home/SuccessStories";
import WatchVideos from "containers/Home/WatchVideos";
import Blogs from "containers/Home/Blogs";
import ContactDetails from "containers/Home/ContactDetails";
import Footer from "containers/Home/Footer";
import { ResetCSS } from "common/assets/css/style";
import "common/assets/css/main-page.css";
import {
  GlobalStyle,
  InteriorWrapper,
  ContentWrapper,
} from "containers/Home/global.style";
import Seo from "components/seo";

const Home = () => {
  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
        <Seo title="Radiance Hair Studio | Hair Wig Solution's" />
        <ResetCSS />
        <GlobalStyle />

        {/* Start writing your markup from here. */}
        <InteriorWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <ContentWrapper>
            <Heroarea />
            <BeforeAfter />
            <GoogleReviews />
            <AboutGrid />
            <WhyRestoration />
            <Features />
            <CallToAction />
            <WhyChoose />
            <Counters />
            <SuccessStories />
            <div className="myContainer">
              <div className="row">
                <div className="col-md-4">
                  <Fade left>
                  <WatchVideos />
                  </Fade>
                </div>
                <div className="col-md-8">
                  <Fade right>
                  <Blogs />
                  </Fade>
                </div>
              </div>
            </div>
            <ContactDetails />
          </ContentWrapper>

          
          <Footer />
        </InteriorWrapper>
        {/* End of markup section. */}
      </Fragment>
    </ThemeProvider>
  );
};
export default Home;
