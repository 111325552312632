import styled from "styled-components";

const SectionWrapper = styled.div`
  .video-details h3 {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700 !important;
  }

  .video-details p {
    color: #A8600E;
}

.swiper-button-next7.swiper-button-disabled, .swiper-button-prev7.swiper-button-disabled {
  opacity: 0.5;
}
  .swiper-button-next7,
  .swiper-button-prev7 {
    color: #a8600e;
    border: 1px solid currentColor;
    padding: 5px 10px;
    border-radius: 100%;
    margin: 5px;
  }

  @media only screen and (max-width: 1440px) {
    h3 {
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
    }
  }
  @media only screen and (max-width: 767px) {

    .video-details p {
      color: #A8600E;
      text-align: left;
  }
  }
`;

export const Container = styled.div`
  max-width: 1627px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1700px) {
    padding: 0 70px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 0;
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const ImageWrapper = styled.div`
  width: calc(100% - 507px);
  padding-right: 75px;
  position: relative;
  @media only screen and (max-width: 1200px) {
    width: 60%;
    padding-right: 50px;
  }
  @media only screen and (max-width: 991px) {
    padding-right: 0;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 30px;
    transform: rotateY(-180deg);
  }
`;

export const TextWrapper = styled.div`
  width: 507px;
  @media only screen and (max-width: 991px) {
    padding-left: 50px;
  }
  @media only screen and (max-width: 767px) {
    padding-left: 0;
    width: 100%;
  }

  h2 {
    @media only screen and (min-width: 1441px) {
      margin-bottom: 60px;
    }
    @media only screen and (min-width: 1440px) {
      margin-bottom: 40px;
    }
    @media only screen and (min-width: 767px) {
      margin-bottom: 30px;
    }
  }

  p {
    margin-bottom: 30px;
  }

  .learn__more-btn {
    margin-top: 20px;
  }
`;

export default SectionWrapper;
