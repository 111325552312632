import styled from "styled-components";


const SectionWrapper = styled.div`
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: visible;
  background-color: #ffa238;
  color: #fff;
  font-weight: 600 !important;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  width:320px;
  bottom: 100%;
  left: 0%;
  margin-left: 0px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
  .tooltip-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #555;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}
  padding: 81px 0;

  .emptySpace {
    height: 85px;
  }
  .emptySpace2 {
    height: 60px;
  }

  .action__btns {
display:none;
  }

  .bef-aft-text span.lef {
    font-weight: 700;
    color: #000000;
    width: 55px;
    text-align: center;
  }

  .bef-aft-text span.righ {
    font-weight: 700;
    color: #000000;
    width: 55px;
    text-align: left;
  }
  .bef-aft-text {
    margin-bottom: -35px;
    z-index: 2;
    display: flex;
    gap: 35px;
  }

  h3.react-reveal.title.results_title {
    font-size: 26px;
    font-weight: 400 !important;
    color: #000000;
  }

  span.btn_heading {
    background: linear-gradient(180deg, #ff9d2d 0%, #a8600e 100%);
    padding: 2px 12px;
    color: #ffffff;
    font-weight: 700;
  }

  .col-md-4 {
    display: flex;
    flex-direction: column;
    align-items: center;

    .btnWrap {
      display: contents;

      .btn-lg {
        width: 280px;
        margin-top: 20px;
      }

      .ba-btn {
        font-family: "Mulish", sans-serif;
        font-size: 20px;
        font-weight: 700;
        text-align: center !important;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        background: linear-gradient(180deg, #ffa238 0%, #a8600e 100%);
        padding: 16.5px 30px;
      }
    }
  }

  a.btn.btn-default.btn-lg.black {
    background: linear-gradient(180deg, #373737 0%, #0f0f0f 100%);
    color: #ffffff;
  }

  .before-after-slider {
    text-align: center;
    margin-top: 20px;
  }

  .slider-container {
    position: relative;
    width: 100%;
  }

  .left-image,
  .right-image {
    display: block;
    height: auto;
    max-width: 100%;
  }

  .slider {
    width: 100%;
  }

  @media only screen and (max-width: 1440px) {
    padding: 60px 0;

    h3 {
      font-size: 28px;
      line-height: 32px;
      font-weight: 500;
    }
  }
  @media only screen and (max-width: 767px) {
    padding: 42px 0 0;

    h3.react-reveal.title.results_title {
      font-size: 20px;
      text-align: center;
    }
    .pt-5 {
      padding-top: 1rem !important;
    }
    .col-md-4 .btnWrap .ba-btn {
      font-size: 16px;
      padding: 15px 30px;
    }
    .col-md-4 .btnWrap .btn-lg {
      width: 100%;
      margin-top: 20px;
      display:none;
    }
    .emptySpace2, .emptySpace {
      height: 0;
      display: none;
    }
    .col-md-4:first-child {
      order: 2;
    }.changc a {
      background: linear-gradient(180deg, #373737 0%, #0f0f0f 100%) !important;
  }
.action__btns a.btn.btn-default.black {
    background: #000000;
    color: #ffffff;
}
.action__btns {
  display: -webkit-box;
  display: -ms-flexbox;
  gap: 20px;
  margin-top: 0px;
  margin-bottom: 35px;
}
  }
`;

export const Container = styled.div`
  max-width: 1627px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1700px) {
    padding: 0 70px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 0;
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const ImageWrapper = styled.div`
  width: calc(100% - 507px);
  padding-right: 75px;
  position: relative;
  @media only screen and (max-width: 1200px) {
    width: 60%;
    padding-right: 50px;
  }
  @media only screen and (max-width: 991px) {
    padding-right: 0;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 30px;
    transform: rotateY(-180deg);
  }
`;



export const TextWrapper = styled.div`
  width: 507px;
  @media only screen and (max-width: 991px) {
    padding-left: 50px;
  }
  @media only screen and (max-width: 767px) {
    padding-left: 0;
    width: 100%;
  }

  h2 {
    @media only screen and (min-width: 1441px) {
      margin-bottom: 60px;
    }
    @media only screen and (min-width: 1440px) {
      margin-bottom: 40px;
    }
    @media only screen and (min-width: 767px) {
      margin-bottom: 30px;
    }
  }

  p {
    margin-bottom: 30px;
  }

  .learn__more-btn {
    margin-top: 20px;
  }
`;


export default SectionWrapper;
