import styled from "styled-components";

const SectionWrapper = styled.div`
  position: relative;

  .fixedText {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 10; /* Ensure this is above the swiper slides */

    h1 {
      font-weight: 400 !important;
      line-height: 1.2;
      color: #000000;
      margin-top: 50px;
      text-align: center;
      font-size: 34px;

      .light {
        color: #d7903f;
        font-weight: 800;
      }
    }

    .action__btns {
      display: flex;
      gap: 20px;
      margin-top: 25px;
      justify-content: center;
      margin-bottom: 20px;

      a.btn.btn-default.black {
        background: #000000;
        color: #ffffff;
      }
    }
  }

  .swiper-slide .hero_slider {
    height: 582px;
    background-size: cover !important;
    background-position: bottom center !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  @media only screen and (max-width: 1440px) {
    padding: 0px 0;
  }

  @media only screen and (max-width: 767px) {
    padding: 0px 0;

    .fixedText h1 {
      font-size: 18px;
    }

    .fixedText{
  position: absolute;
  top: 16%;
  width: 100%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 10;
  }


    .fixedText .action__btns {
      flex-direction: column;
    }

    .fixedText .action__btns .linkbtn a {
      font-weight: 600;
    }

    .fixedText .action__btns {
      display: none;
    }

    .swiper-slide .hero_slider {
      height: 235px;
    }

    .fixedText h2 {
      margin-top: 45px;
    }
  }

  .btn-default {
    background: linear-gradient(180deg, #FFA238 0%, #8A4A00 100%);
    color: #ffffff;
    border-radius: 3px;
    border: 1px solid linear-gradient(180deg, #FFA238 0%, #8A4A00 100%);
    padding: 10px 20px;
    margin: 6px;
    font-weight: 600;
  }
`;

export const Container = styled.div`
  max-width: 1627px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  position: relative;

  @media only screen and (max-width: 1700px) {
    padding: 0 70px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 0 30px;
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const ImageWrapper = styled.div`
  width: calc(100% - 507px);
  padding-right: 75px;
  position: relative;

  @media only screen and (max-width: 1200px) {
    width: 60%;
    padding-right: 50px;
  }
  @media only screen and (max-width: 991px) {
    padding-right: 0;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 30px;
    transform: rotateY(-180deg);
  }
`;

export const TextWrapper = styled.div`
  width: 507px;

  @media only screen and (max-width: 991px) {
    padding-left: 50px;
  }
  @media only screen and (max-width: 767px) {
    padding-left: 0;
    width: 100%;
  }

  h2 {
    @media only screen and (min-width: 1441px) {
      margin-bottom: 60px;
    }
    @media only screen and (min-width: 1440px) {
      margin-bottom: 40px;
    }
    @media only screen and (min-width: 767px) {
      margin-bottom: 30px;
    }


  }

  p {
    margin-bottom: 30px;
  }

  .learn__more-btn {
    margin-top: 20px;
  }
`;

export default SectionWrapper;
