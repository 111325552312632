import styled from "styled-components";

const SectionWrapper = styled.div`
  padding: 81px 0;

  .modal-header {
    padding: 0;
    border: 0;
  }

span.btn-close2 {
  background: #d18c3c;
  padding: 3px 12px;
  position: absolute;
  right: -20px;
  border-radius: 100%;
  z-index: 2;
  font-size: 20px;
  color: #ffffff;
  cursor: pointer;
}

.modal-header button {
  position: absolute;
  right: 0;
}

.modal-body {
  padding: 0;
  margin-bottom: -6px;
}

div#exampleModal {
  background: rgba(0,0,0,0.5);
  top: 0;
  padding-top: 100px;
}

  p {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: #373737;
  }

  .btn-default {
    background: linear-gradient(180deg, #ffa238 0%, #8a4a00 100%);
  }

  h2 {
    font-size: 30px;
    font-weight: 700 !important;
    line-height: 1.4;
    color: #000000;
  }

  p.spaceddText {
    padding-left: 15px;
    border-left: 5px solid #a8600e;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  @media only screen and (min-width: 767px) {

    .modal-dialog {
      max-width: 800px;
      margin-right: auto;
      margin-left: auto;
  }

  
    .parentGrid {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(9, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 20px;
    }

    .divGrid1 {
      grid-area: 1 / 2 / 3 / 8;
    }
    .divGrid2 {
      grid-area: 1 / 8 / 5 / 12;
    }
    .divGrid3 {
      grid-area: 3 / 3 / 7 / 8;
    }
    .divGrid4 {
      grid-area: 3 / 1 / 10 / 3;
    }
    .divGrid5 {
      grid-area: 7 / 4 / 11 / 8;
    }
    .divGrid6 {
      grid-area: 5 / 8 / 11 / 11;
    }
    .divGrid7 {
      grid-area: 5 / 11 / 11 / 13;
    }
  }

  @media only screen and (max-width: 1440px) {
    padding: 60px 0;
  }
  @media only screen and (max-width: 767px) {
    padding: 42px 0;

    .parentGrid {
      padding-left: 10px;
      padding-right: 10px;
    }
    .divGrid3 > p {
      margin-top: 20px !important;
    }
    .divGrid7,
    .divGrid6,
    .divGrid4 {
      display: none;
    }
    .divGrid5 {
      margin-top: 30px;
    }
  }
`;

export const Container = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1700px) {
    // padding: 0 70px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 0px;
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const ImageWrapper = styled.div`
  width: calc(100% - 507px);
  padding-right: 75px;
  position: relative;
  @media only screen and (max-width: 1200px) {
    width: 60%;
    padding-right: 50px;
  }
  @media only screen and (max-width: 991px) {
    padding-right: 0;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 30px;
    transform: rotateY(-180deg);
  }
`;

export const TextWrapper = styled.div`
  width: 507px;
  @media only screen and (max-width: 991px) {
    padding-left: 50px;
  }
  @media only screen and (max-width: 767px) {
    padding-left: 0;
    width: 100%;
  }

  h2 {
    @media only screen and (min-width: 1441px) {
      margin-bottom: 60px;
    }
    @media only screen and (min-width: 1440px) {
      margin-bottom: 40px;
    }
    @media only screen and (min-width: 767px) {
      margin-bottom: 30px;
    }
  }

  p {
    margin-bottom: 30px;
  }

  .learn__more-btn {
    margin-top: 20px;
  }
`;

export default SectionWrapper;
